export enum ModulesEnum {
  UsersModule = 'UsersController',
  DatabaseModule = 'DatabaseController',
  AccountUsersModule = 'AccountUsersController',
  AccountRolesModule = 'AccountRolesController',
  AccountSettingsModule = 'AccountSettingsController',
  AccountStorageModule = 'AccountStorageController',

  ReportsModule = 'ReportsController',
  ActivityDashboardModule = 'ActivityDashboardController',
  GroupsDashboardModule = 'GroupsDashboardController',
  GroupsSummaryModule = 'GroupsSummaryController',
  HuntGroupsDashboardModule = 'HuntGroupsDashboardController',
  UsersSummaryModule = 'UsersSummaryController',
  CustomReportsModule = 'CustomReportsController',
  CallLogsModule = 'CallLogsController',
  BasicCallLogsModule = 'BasicCallLogsController',
  RecordingsModule = 'RecordingsController',
  SurveysModule = 'SurveysController',
  RecordingsExtensionsModule = 'RecordingsExtensionsController',
  PhoneNumbersModule = 'PhoneNumbersController',
  PhoneNumbersExportModule = 'PhoneNumbersExportController',
  FirewallModule = 'FirewallController',

  MessagingModule = 'MessagingController',
  ProMessagingModule = 'ProMessagingController',
  MessageBroadcastingModule = 'MessageBroadcastingController',
  MessagingAutomationModule = 'MessagingAutomationController',
  MessagingTemplateModule = 'MessagingTemplateController',
  MessagingPrivateTemplateModule = 'MessagingPrivateTemplateController',
  ProMessagingReportsModule = 'ProMessagingReportsController',
  MessagingWhatsAppTemplateModule = 'MessagingWhatsAppTemplateController',
  MessagingWhatsAppOnBoardModule = 'MessagingWhatsAppTemplateController',

  ActivityDashboardV2Module = 'ActivityDashboardV2Controller',
  CallLogsV2Module = 'CallLogsV2Controller',
  GroupsSummaryV2Module = 'GroupsSummaryV2Controller',
  UsersSummaryV2Module = 'UsersSummaryV2Controller',

  PbxModule = 'PbxController',
  PbxHolidayGreetingsModule = 'PbxHolidayGreetingsController',
  PbxAccountsModule = 'PbxAccountsController',
  PbxWallboardModule = 'PbxWallboardController',

  FaxModule = 'FaxController',
  FaxReportModule = 'FaxReportController',
  ContactModule = 'ContactController',
  PrivateContactModule = 'PrivateContactController',
  TagsModule = 'TagsController',
  AuditModule = 'AuditController',

  EventsFlowModule = 'EventsFlowController',

  SoftPhoneModule = 'SoftPhoneController',
  BasicSoftPhoneModule = 'BasicSoftPhoneController',
  DownloadsModule = 'DownloadsController',
  VideoConferenceModule = 'VideoConferenceController',

  HhaModule = 'HhaController',
  CustomFieldsModule = 'CustomFieldsController',
}
