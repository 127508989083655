/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PbxActiveCallModel } from '../models/pbx-active-call-model';
import { PbxGroupCallModel } from '../models/pbx-group-call-model';
import { pbxWallboardGetActiveDomainCalls } from '../fn/wallboard/pbx-wallboard-get-active-domain-calls';
import { PbxWallboardGetActiveDomainCalls$Params } from '../fn/wallboard/pbx-wallboard-get-active-domain-calls';
import { pbxWallboardGetWallboardCalls } from '../fn/wallboard/pbx-wallboard-get-wallboard-calls';
import { PbxWallboardGetWallboardCalls$Params } from '../fn/wallboard/pbx-wallboard-get-wallboard-calls';
import { pbxWallboardGetWallboardInfo } from '../fn/wallboard/pbx-wallboard-get-wallboard-info';
import { PbxWallboardGetWallboardInfo$Params } from '../fn/wallboard/pbx-wallboard-get-wallboard-info';
import { pbxWallboardGetWallboards } from '../fn/wallboard/pbx-wallboard-get-wallboards';
import { PbxWallboardGetWallboards$Params } from '../fn/wallboard/pbx-wallboard-get-wallboards';
import { PbxWallboardLiveModel } from '../models/pbx-wallboard-live-model';
import { PbxWallboardModel } from '../models/pbx-wallboard-model';

@Injectable({ providedIn: 'root' })
export class WallboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxWallboardGetWallboards()` */
  static readonly PbxWallboardGetWallboardsPath = '/api/wallboard/wallboards';

  /**
   * Get Available Wallboards.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxWallboardGetWallboards()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboards$Response(params?: PbxWallboardGetWallboards$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PbxWallboardModel>>> {
    return pbxWallboardGetWallboards(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Available Wallboards.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxWallboardGetWallboards$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboards(params?: PbxWallboardGetWallboards$Params, context?: HttpContext): Observable<Array<PbxWallboardModel>> {
    return this.pbxWallboardGetWallboards$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PbxWallboardModel>>): Array<PbxWallboardModel> => r.body)
    );
  }

  /** Path part for operation `pbxWallboardGetWallboardInfo()` */
  static readonly PbxWallboardGetWallboardInfoPath = '/api/wallboard/wallboard/{id}';

  /**
   * Get Wallboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxWallboardGetWallboardInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboardInfo$Response(params: PbxWallboardGetWallboardInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<PbxWallboardLiveModel>> {
    return pbxWallboardGetWallboardInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Wallboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxWallboardGetWallboardInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboardInfo(params: PbxWallboardGetWallboardInfo$Params, context?: HttpContext): Observable<PbxWallboardLiveModel> {
    return this.pbxWallboardGetWallboardInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxWallboardLiveModel>): PbxWallboardLiveModel => r.body)
    );
  }

  /** Path part for operation `pbxWallboardGetActiveDomainCalls()` */
  static readonly PbxWallboardGetActiveDomainCallsPath = '/api/wallboard/active-domain-calls';

  /**
   * Get Active Domain Calls.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxWallboardGetActiveDomainCalls()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetActiveDomainCalls$Response(params?: PbxWallboardGetActiveDomainCalls$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PbxActiveCallModel>>> {
    return pbxWallboardGetActiveDomainCalls(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Active Domain Calls.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxWallboardGetActiveDomainCalls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetActiveDomainCalls(params?: PbxWallboardGetActiveDomainCalls$Params, context?: HttpContext): Observable<Array<PbxActiveCallModel>> {
    return this.pbxWallboardGetActiveDomainCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PbxActiveCallModel>>): Array<PbxActiveCallModel> => r.body)
    );
  }

  /** Path part for operation `pbxWallboardGetWallboardCalls()` */
  static readonly PbxWallboardGetWallboardCallsPath = '/api/wallboard/wallboard/{id}/calls';

  /**
   * Get Wallboard Calls.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxWallboardGetWallboardCalls()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboardCalls$Response(params: PbxWallboardGetWallboardCalls$Params, context?: HttpContext): Observable<StrictHttpResponse<PbxGroupCallModel>> {
    return pbxWallboardGetWallboardCalls(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Wallboard Calls.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxWallboardGetWallboardCalls$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxWallboardGetWallboardCalls(params: PbxWallboardGetWallboardCalls$Params, context?: HttpContext): Observable<PbxGroupCallModel> {
    return this.pbxWallboardGetWallboardCalls$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxGroupCallModel>): PbxGroupCallModel => r.body)
    );
  }

}
