import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, forwardRef, NgModule, Provider} from '@angular/core';
import {AppComponent} from './app.component';
import {AuthGuard} from './core/security/auth-guard.service';
import {AuthService} from './core/security/auth.service';
import {RouterHelper} from './core/utilities/router-helper';
import {HeaderService} from './core/services/header.service';
import {DialogsModule} from './dialogs/dialogs.module';
import {AppRoutingModule} from './app-routes.module';
import {NotificationComponent} from './notification/notification.component';
import {NotificationService} from './notification/notification.service';
import {GoogleAnalyticsService} from './analytics/google-analytics.service';
import {EventsService} from './events/events.service';
import * as moment from 'moment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptor} from './core/interceptors/api-interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ApiConfiguration} from './api/api-configuration';
import {CommonModule} from '@angular/common';
import {ApiModule} from './api/api.module';
import {HelpscoutService} from './core/services/helpscout.service';
import {LocalNotificationsService} from './core/services/local-notifications.service';
import {ZendeskService} from './core/services/zendesk.service';
import {CookieService} from 'ngx-cookie-service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {NgxEchartsModule} from 'ngx-echarts';
import * as echarts from 'echarts';
import * as ecSimpleTransform from 'echarts-simple-transform';
import {FlexModule} from '@angular/flex-layout';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  // useClass: ApiInterceptor,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = '';
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true,
};

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ApiModule,
    DialogsModule,
    NgxEchartsModule.forRoot({
      echarts: echarts,
    }),
    FlexModule,
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    INIT_API_CONFIGURATION,
    CookieService,
    AuthGuard,
    AuthService,
    EventsService,
    HeaderService,
    RouterHelper,
    NotificationService,
    GoogleAnalyticsService,
    LocalNotificationsService,
    HelpscoutService,
    ZendeskService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private gaService: GoogleAnalyticsService, private headerService: HeaderService) {
    window.addEventListener('error', function (event) {
      console.error('Caught an error:', event.message);
      return false;
    });

    echarts.registerTransform(ecSimpleTransform.aggregate);

    gaService.initGA();

    AppModule.initMoment();

    //TODO: uncomment this if need to generate new route
    /*RouterGenerator.initAllModules().then(() => {
      const allRoutesJson = JSON.stringify(RouterGenerator.allRoutes, null, 2);

      const fileTemplate = `import {RouteHelperItem} from '../utilities/router-helper';

export const routes: RouteHelperItem[] = {{routes}};

`;

      let fileSource = fileTemplate.replace('{{routes}}', allRoutesJson);
      fileSource = fileSource.replace(/"/g, '\'');
      console.log(fileSource)
      debugger;
    })*/
  }

  static initMoment(): void {
    moment.updateLocale('en', {
      relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '%d s',
        ss: '%d s',
        m: '%d min',
        mm: '%d min',
        h: '%d h',
        hh: '%d h',
        d: '%d d',
        dd: '%d d',
        M: '%d m',
        MM: '%d m',
        y: '%d y',
        yy: '%d y',
      },
    });
  }
}
